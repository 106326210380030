import { DialogButtonType } from '@/components/dialog/dialog';
import LoadingMask from '@/components/loadingMask/loadingMask';
import PartnerAgentListComp from '@/components/partnerAgentListComp';
import useFetchApi from '@/hooks/useFetchApi';
import { ISaveSubAccountRequest, ISubAccountDetails, PartnerAgentList, SaveType, SystemEditAccountMode, UserRoleEnum } from '@/models/page/systemSetting';
import { api } from '@/service/api';
import { showDialog } from '@/store/slices/dialog.slice';
import { Textarea, Text, Input, RadioGroup, Stack, Radio, Select } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SystemSettingLayoutEnum } from '../../layout.mapping';

type Props = {
    userId?: number;
    mode?: SystemEditAccountMode;
    type?: SystemSettingLayoutEnum;
    onClose?: () => void;
};

const EditAccount = ({ userId, mode, type, onClose }: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const isReadOnly = useMemo(() => {
        return mode === SystemEditAccountMode.UPDATE;
    }, [mode]);

    const isRequirmentMode = useMemo(() => {
        return mode === SystemEditAccountMode.CREATE;
    }, [mode]);

    const [defaultPartnerAgentList, setDefaultPartnerAgentList] = useState<Record<string, PartnerAgentList[]>>();

    const fetchDefaultPartnerAgentList = useFetchApi<unknown, Record<string, PartnerAgentList[]>>(
        api.getUserPartnerAgentList,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value }) => {
            setDefaultPartnerAgentList(value.data.Data);
            setIsLoading(false);
        },
        [],
        () => {
            setIsLoading(false);
        },
    );

    const fetchSubAccountDetails = useFetchApi<{ UserId: number }, ISubAccountDetails>(
        api.subAccountDetails,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value }) => {
            if (value.data.IsSuccess) {
                setDefaultPartnerAgentList(value.data.Data.PartnerAgents);
                setUserName(value.data.Data.Account);
                setName(value.data.Data.UserName);
                setNote(value.data.Data.Remark);
                setRole(value.data.Data.RoleType);
                setStatus(value.data.Data.IsActive ? '1' : '2');
                const flatActiveList = Object.values(value.data.Data.PartnerAgents)
                    .reduce((prev, curr) => {
                        return [...prev, ...curr];
                    }, [])
                    .filter((v) => v.IsAppliedAgent)
                    .map(({ AgentUserCode, PartnerId, IsAppliedAgent }) => ({ AgentUserCode, PartnerId, IsAppliedAgent }));
                setAgentList(flatActiveList);
            }
            setIsLoading(false);
        },
        [],
        () => {
            setIsLoading(false);
        },
    );

    const parseCreateAccountRespMessage = useCallback((msg: string) => {
        if (msg.includes('Account is duplicated')) {
            return 'Lbl_accountIsDuplicated';
        } else {
            return 'Txt_createFailed';
        }
    }, []);

    const createAndUpdateAccount = useFetchApi<ISaveSubAccountRequest>(
        api.saveAndUpdateSubAccount,
        ({ value }) => {
            setIsLoading(true);
            return value;
        },
        ({ value, deps: [close, i18n, _mode, _userId, _parseCreateAccountRespMessage] }) => {
            const message = value.data.Message;
            if (value.data.IsSuccess) {
                dispatch(
                    showDialog({
                        title: i18n('Lbl_Button_Info'),
                        content: i18n(_mode === SystemEditAccountMode.CREATE ? 'Txt_createSuccess' : 'Txt_updateSuccess'),
                        onClose: () => {
                            _mode === SystemEditAccountMode.CREATE && close?.();
                            _mode === SystemEditAccountMode.UPDATE && fetchSubAccountDetails({ UserId: _userId });
                        },
                        type: DialogButtonType.Info,
                    }),
                );
            } else {
                dispatch(
                    showDialog({
                        title: i18n('Lbl_Button_Info'),
                        content: i18n(_mode === SystemEditAccountMode.CREATE ? _parseCreateAccountRespMessage(message) : 'Txt_updateFailed'),
                        onClose: () => {},
                        type: DialogButtonType.Warning,
                    }),
                );
            }
            setIsLoading(false);
        },
        [onClose, t, mode, userId, parseCreateAccountRespMessage],
        () => {
            setIsLoading(false);
        },
    );
    useEffect(() => {
        if (mode === SystemEditAccountMode.CREATE) {
            fetchDefaultPartnerAgentList({});
        } else if (mode === SystemEditAccountMode.UPDATE) {
            userId !== undefined && fetchSubAccountDetails({ UserId: userId });
        }
    }, [mode]);

    const [selectedAgentList, setAgentList] = useState<PartnerAgentList[]>([]);
    const [userName, setUserName] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [note, setNote] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [role, setRole] = useState<UserRoleEnum>(UserRoleEnum.None);
    const [status, setStatus] = useState<string>('1');

    const PartnerAgentListCompProp = useMemo(() => {
        return {
            defaultPartnerAgentList,
            selectedAgentList,
            onChange: (currentPartnerId: number, list: (string | number)[]) => {
                const selectedList = list.map((item) => ({ AgentUserCode: item.toString(), PartnerId: currentPartnerId, IsAppliedAgent: true }));

                setAgentList((o) => {
                    const _list = o.filter((v) => v.PartnerId !== currentPartnerId);
                    return [..._list, ...selectedList];
                });
            },
        };
    }, [defaultPartnerAgentList, selectedAgentList]);

    const currentRoleList = useMemo(() => {
        return type === SystemSettingLayoutEnum.ACCOUNT_MANAGEMENT
            ? [UserRoleEnum.SuperUser, UserRoleEnum.Agent]
            : [
                  UserRoleEnum.SubAgent,
                  UserRoleEnum.SeniorSubAgent,
                  UserRoleEnum.CommonManager,
                  UserRoleEnum.CommonManager2,
                  UserRoleEnum.CommonManager3,
                  UserRoleEnum.CommonManager4,
                  UserRoleEnum.CommonManager5,
                  UserRoleEnum.CommonManager6,
                  UserRoleEnum.CommonManager7,
                  UserRoleEnum.CommonManager8,
              ];
    }, [type]);

    const [validateFailedList, setValidateFailedList] = useState<string[]>([]);
    const validateAllPass = useCallback(() => {
        const checkPassword = mode === SystemEditAccountMode.CREATE ? password !== '' && confirmPassword === password : true;
        return selectedAgentList.length > 0 && userName !== '' && name !== '' && checkPassword;
    }, [selectedAgentList, userName, name, password, confirmPassword, role, mode]);

    const requestItem = useMemo<ISaveSubAccountRequest>(() => {
        const result = {
            SaveType: mode === SystemEditAccountMode.CREATE ? SaveType.CREATE : SaveType.Edit,
            Account: userName,
            RoleType: role,
            UserName: name,
            Remark: note,
            IsActive: status === '1',
            PartnerAgents: selectedAgentList,
        };

        return mode === SystemEditAccountMode.CREATE ? { ...result, Password: password } : { ...result, UserId: userId };
    }, [selectedAgentList, userName, name, password, role, note, status, mode, userId]);

    const onConfirmHandler = useCallback(() => {
        const validatePass = validateFailedList.length === 0 && validateAllPass();

        if (validatePass) {
            createAndUpdateAccount(requestItem);
        } else {
            dispatch(
                showDialog({
                    title: '警告',
                    content: '请填写必要栏位，并确认密码是否正确',
                    type: DialogButtonType.Warning,
                }),
            );
        }
    }, [mode, validateFailedList, validateAllPass, requestItem]);

    return (
        <>
            <div className="flex w-full flex-col">
                <div className="flex w-1/2 m-2">
                    <div className="flex flex-col w-1/2">
                        <div className="flex items-center w-full p-2">
                            <Text
                                data-active={isRequirmentMode}
                                className="mr-4 whitespace-nowrap data-active:before:content-['*'] data-active:before:text-red-500 after:content-[':']">
                                {t('Txt_username')}
                            </Text>
                            <Input
                                disabled={isReadOnly}
                                isInvalid={validateFailedList.includes('userName')}
                                className="flex-1 disabled:bg-gray-300"
                                maxLength={20}
                                errorBorderColor="crimson"
                                value={userName}
                                placeholder=""
                                onChange={(e) => {
                                    setUserName(e.target.value);
                                }}
                                onBlur={(e) => {
                                    const value = e.target.value;
                                    setValidateFailedList((list) => {
                                        if (value === '' || !value.match(/^[a-zA-Z0-9_]+$/)) {
                                            return list.concat(['userName']);
                                        }
                                        return list.filter((v) => v !== 'userName');
                                    });
                                }}
                            />
                        </div>
                        {mode === SystemEditAccountMode.CREATE && (
                            <>
                                <div className="flex items-center w-full p-2">
                                    <Text
                                        data-active={isRequirmentMode}
                                        className="mr-4 whitespace-nowrap data-active:before:content-['*'] data-active:before:text-red-500 after:content-[':']">
                                        {t('Lbl_SubAccountPassword')}
                                    </Text>
                                    <Input
                                        isInvalid={validateFailedList.includes('password')}
                                        type="password"
                                        className="flex-1 disabled:bg-gray-300"
                                        errorBorderColor="crimson"
                                        value={password}
                                        maxLength={20}
                                        placeholder=""
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                        onBlur={(e) => {
                                            setValidateFailedList((list) => {
                                                e.target.value === '' ? list.push('password') : (list = list.filter((v) => v !== 'password'));
                                                return [...list];
                                            });
                                        }}
                                    />
                                </div>
                                <div className="flex items-center w-full p-2">
                                    <Text
                                        data-active={isRequirmentMode}
                                        className="mr-4 whitespace-nowrap data-active:before:content-['*'] data-active:before:text-red-500 after:content-[':']">
                                        {t('Lbl_ConfirmSubAccountPassword')}
                                    </Text>
                                    <Input
                                        isInvalid={validateFailedList.includes('confirmPassword')}
                                        type="password"
                                        className="flex-1 disabled:bg-gray-300"
                                        errorBorderColor="crimson"
                                        value={confirmPassword}
                                        maxLength={20}
                                        placeholder=""
                                        onChange={(e) => {
                                            setConfirmPassword(e.target.value);
                                        }}
                                        onBlur={(e) => {
                                            setValidateFailedList((list) => {
                                                e.target.value === '' || e.target.value !== password
                                                    ? list.push('confirmPassword')
                                                    : (list = list.filter((v) => v !== 'confirmPassword'));
                                                return [...list];
                                            });
                                        }}
                                    />
                                </div>
                            </>
                        )}

                        <div className="flex items-center w-full p-2">
                            <Text
                                data-active={isRequirmentMode}
                                className="mr-4 whitespace-nowrap data-active:before:content-['*'] data-active:before:text-red-500 after:content-[':']">
                                {t('Th_Role')}
                            </Text>

                            <Select
                                isInvalid={validateFailedList.includes('role')}
                                className="flex-1 disabled:bg-gray-300"
                                // disabled={!editable}
                                value={role}
                                onChange={(e) => {
                                    setRole(+e.target.value);
                                }}
                                onBlur={(e) => {
                                    setValidateFailedList((list) => {
                                        e.target.value === UserRoleEnum.None.toString() ? list.push('role') : (list = list.filter((v) => v !== 'role'));
                                        return [...list];
                                    });
                                }}>
                                {mode === SystemEditAccountMode.CREATE && <option value={UserRoleEnum.None}>{t('Lbl_PleaseSelect')}</option>}
                                {currentRoleList.map((item) => (
                                    <option key={`role-${item}`} value={item}>
                                        {t(`Lbl_Role_${item}`)}
                                    </option>
                                ))}
                            </Select>
                        </div>
                        <div className="flex items-center w-full p-2">
                            <Text
                                data-active={isRequirmentMode}
                                className="mr-4 whitespace-nowrap data-active:before:content-['*'] data-active:before:text-red-500 after:content-[':']">
                                {t('Th_status')}
                            </Text>
                            <RadioGroup onChange={setStatus} value={status}>
                                <Stack direction="row">
                                    <Radio value="1">{t('Lbl_enable')}</Radio>
                                    <Radio value="2">{t('Lbl_disable')}</Radio>
                                </Stack>
                            </RadioGroup>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                        <div className="flex items-center w-full p-2">
                            <Text
                                data-active={isRequirmentMode}
                                className="mr-4 whitespace-nowrap data-active:before:content-['*'] data-active:before:text-red-500 after:content-[':']">
                                {t('Txt_name')}
                            </Text>
                            <Input
                                className="flex-1 disabled:bg-gray-300"
                                isInvalid={validateFailedList.includes('name')}
                                onBlur={(e) => {
                                    setValidateFailedList((list) => {
                                        e.target.value === '' ? list.push('name') : (list = list.filter((v) => v !== 'name'));
                                        return [...list];
                                    });
                                }}
                                value={name}
                                placeholder=""
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </div>
                        <div className="flex items-start w-full p-2">
                            <Text className="mr-4 mt-2 whitespace-nowrap after:content-[':']">{t('Lbl_note')}</Text>
                            <Textarea
                                className="flex-1 disabled:bg-gray-300"
                                value={note}
                                maxLength={100}
                                onChange={(e) => {
                                    setNote(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div data-partner-agent-list className="flex flex-col w-1/2 m-2">
                    <Text className="m-2 whitespace-nowrap before:content-['*'] before:text-red-500 after:content-[':'] ">{t('Lbl_AgentAccountSetting')}</Text>
                    <div className="m-2">
                        <PartnerAgentListComp {...PartnerAgentListCompProp} />
                    </div>
                </div>

                <div data-submit className="flex justify-end w-1/2 m-2">
                    {/* <button className=" m-2 w-24 h-8 bg-gray-400 text-white rounded hover:bg-gray-500" onClick={() => {}}>
                        <label className="px-1 cursor-pointer">{t('Lbl_Button_Cancle')}</label>
                    </button> */}

                    <button className=" m-2 w-24 h-8 bg-affiliate text-white rounded hover:bg-gray-500" onClick={onConfirmHandler}>
                        <label className="px-1 cursor-pointer">{t('Btn_confirm')}</label>
                    </button>
                </div>
            </div>
            <LoadingMask visible={isLoading} />
        </>
    );
};

export default EditAccount;
