export enum UserRoleEnum {
    None = 0,
    SuperUser = 1,
    Agent = 2,
    SubAgent = 3,
    SeniorSubAgent = 5,
    CommonManager = 4,
    CommonManager2 = 6,
    CommonManager3 = 7,
    CommonManager4 = 8,
    CommonManager5 = 9,
    CommonManager6 = 10,
    CommonManager7 = 11,
    CommonManager8 = 12,
}

export enum UserRoleStatusEnum {
    Enable = 1,
    Disable = 2,
}

export enum SystemEditAccountMode {
    NONE,
    CREATE,
    UPDATE,
    RESET,
    ACTIVE,
}

export type PartnerAgentList = {
    PartnerId: number;
    AgentUserCode: string;
    IsAppliedAgent?: boolean;
};

export interface ISystemAccount {
    Account: string;
    DateCreated: string;
    IsActive: boolean;
    ParentAccount: string;
    ParentUserId: number;
    PartnerAgentCounts: { PartnerId: number; Count: number }[];
    UserId: number;
    UserName: number;
    RoleType: UserRoleEnum;
}

export interface ISubAccountDetails {
    UserId: number;
    Account: string;
    RoleType: UserRoleEnum;
    IsActive: boolean;
    UserName: string;
    Remark: string;
    PartnerAgents: Record<string, PartnerAgentList[]>;
    VChatId?: string;
}

export enum SaveType {
    CREATE,
    Edit,
    UPDATE_PASSWORD,
    UPDATE_STATUS,
}

export interface ISaveSubAccountRequest {
    SaveType: SaveType;
    Account: string;
    Password?: string;
    RoleType?: UserRoleEnum;
    UserName?: string;
    Remark?: string;
    IsActive?: boolean;
    PartnerAgents?: PartnerAgentList[];
    UserId?: number;
    VChatId?: string;
}
